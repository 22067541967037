import request from "@/utils/request";
import qs from 'qs'
/* 手机号码修改 */
// /back/hand/call/updateStudentAccount
/* 兼职销售 */
/* 兼职销售分页查询 */
export const apiGetParttimeSaleList = (params) => {
    return request.get('/back/partTime/sales/pageList', params)
}
/* 兼职销售删除 */
export const apiDeleteParttimeSale = (params) => {
    return request.post(`/back/partTime/sales/remove?${qs.stringify(params, { indices: false })}`)
}
/* 兼职销售新增 */
export const apiAddParttimeSale = (params) => {
    return request.post(`/back/partTime/sales/save`, params)
}
/* 兼职销售修改 */
export const apiEditParttimeSale = (params) => {
    return request.post('/back/partTime/sales/update', params)
}
/* 兼职销售有效无效 */
export const apiStatusParttimeSale = (params) => {
    return request.post(`/back/partTime/sales/update/state?${qs.stringify(params, { indices: false })}`)
}
/* 跟进记录 */
/* 跟进记录列表 */
export const apiGetFollowList = (params) => {
    return request.get('/back/student/followupRecord/list', params)
}
/* 删除跟进记录 */
export const apiDeleteFollow = (params) => {
    return request.post(`/back/student/followupRecord/remove?${qs.stringify(params, { indices: false })}`)
}
/* 新增跟进记录 */
export const apiAddFollow = (params) => {
    return request.post('/back/student/followupRecord/save', params)
}
/* 修改跟进记录 */
export const apiEditFollow = (params) => {
    return request.post('/back/student/followupRecord/update', params)
}


/* 学生删除 */
export const apiDeleteStudent = (params) => {
    return request.post(`/back/student/sale/dial/batchRemove?${qs.stringify(params, { indices: false })}`)
}
/* 学生详情 */
export const apiGetStudentDetail = (params) => {
    return request.get('/back/student/pool/getDetailById', params)
}
/* 获取销售和兼职列表 */
export const apiGetSaleAndParttimeList = (params) => {
    return request.get('/back/student/pool/getSaleAndParttimeList', params)
}
/* 呼出通话记录分页查询 */
export const apiGetPoolCallList = (params) => {
    return request.get('/back/student/pool/callLog/pageList', params)  
}

/* 学生是否有效 */
export const apiSetStudentEffective = (params) => {
    return request.post(`/back/student/sale/dial/setEffective?${qs.stringify(params, { indices: false })}`)
}

/* 获取黑名单列表 */
export const apiGetBlackList = (params) => {
    return request.get('/back/student/pool/blackList/pageList', params)
}

/* 审核黑名单 */
export const apiAuditingBlack = (params) => {
    return request.post(`/back/student/pool/auditingBlackList?${qs.stringify(params, { indices: false })}`)
} 

/* 移除黑名单 */
export const apiRemoveBlackList = (params) => {
    return request.post(`/back/student/pool/removeBlackList?${qs.stringify(params, { indices: false })}`)
}

/* 删除意向表分页查询 */
export const apiGetDelectIntentionList = (params) => {
    return request.get('/back/student/sale/dial/deleteIntention/pageList', params)
}

/* 查询系统中所有销售 */
export const apiGetAllSaleList = () => {
    return request.get('/back/student/sale/dial/getAllSales')
}

/* 删除意向列表批量分配学生 */
export const apiBatchAlloction = (params) => {
    return request.post(`/back/student/sale/dial/batchAllocation?${qs.stringify(params, { indices: false })}`)
}

/* 删除意向列表批量恢复学生 */
export const apiBatchRestore = (params) => {
    return request.post(`/back/student/sale/dial/batchRestore?${qs.stringify(params, { indices: false })}`)
}

/* 导入数据学生分页查询 */
export const apiGetBatchRecordList = (params) => {
    return request.get('/back/student/pool/batchRecord/pageList', params)
}
/* 删除导入的学生 */
export const apiDeleteBatchRecordList = (params) => {
    return request.post(`/back/student/pool/deleteStudentBatchRecord?${qs.stringify(params, { indices: false })}`)
}
/* 导入批次数据是否开启外呼 */
export const apiSetOpenOutBound = (params) => {
    return request.post(`/back/student/pool/setOpenOutbound?${qs.stringify(params, { indices: false })}`)
}
/* 导入批次学生 */
export const apiImportFileStudent = (params, file) => {
    let formData = new FormData()
    formData.append('files', file)
    return request.post(`/back/student/pool/importFile?${qs.stringify(params, { indices: false })}`, formData, {
        headers: {
            'Content-type': 'multipart/form-data'
        },
        timeout: 0,   // 导入文件超时时间
    })
}
/* 导入批次学生test */
export const apiImportFileStudentTest = (params, file) => {
    let formData = new FormData()
    formData.append('files', file)
    return request.post(`/back/student/pool/testImportFile?${qs.stringify(params, { indices: false })}`, formData, {
        headers: {
            'Content-type': 'multipart/form-data'
        },
        timeout: 0,   // 导入文件超时时间
    })
}
/* 将导入批次数据分配给销售 */
export const apiImportAllocToSales = (params) => {
    return request.post('/back/student/pool/allocationToSales', params)
}
/* 查询系统中所有销售 */
export const apiGetAllSalesForImportAlloc = (params = {}) => {
    return request.get('/back/student/pool/getAllSales', params)
}
/* 根据批次id查询分配详情 */
export const apiGetImportAllocDetail = (params) => {
    return request.get('/back/student/pool/getDetailByBatchRecordId', params)
}
/* 单批次数据详情分页查询 */
export const apiGetBatchDetail = (params) => {
    return request.get('/back/student/pool/batchDetail/pageList', params)
}
/* 单批次统计分页查询 */
export const apiGetBatchStatistics = (params) => {
    return request.get('/back/student/pool/batchStatistics/pageList', params)
}
/* 电话管理池总电话池分页查询 */
export const apiGetPoolStudentList = (params) => {
    return request.get('/back/student/pool/phone/pageList', params)
}

/* 将某一批次个别学生加入黑名单 */
export const apiSetToBlackList = (params) => {
    return request.post(`/back/student/pool/setToBlackList?${qs.stringify(params, { indices: false })}`)
}

/* 获取批次列表 */
export const apiGetImportListNotPage = () => {
    return request.get('/back/student/pool/getBatchRecordList')
}

/* 查看外呼任务详情 */
export const apiGetCallTaskDetail = (params) => {
    return request.get("/dky/predictCall/info", params)
}
/* 关闭外呼任务 */
export const apiCallListClose = (params) => {
    return request.post(`/dky/predictCall/close?${qs.stringify(params, { indices: false })}`)
}
/* 创建预测外呼任务 */
export const apiCreateCallList = (params) => {
    return request.post(`/dky/predictCall/create`, params)
}
/* 修改预测外呼任务 */
export const apiUpdateCallTask = (params) => {
    return request.post('/dky/predictCall/update', params)
}
/* 预测外呼分页记录 */
export const apiGetCallListPage = (params) => {
    return request.post(`/dky/predictCall/list?${qs.stringify(params, { indices: false })}`)
}
/* 预测外呼被叫号码分页记录 */
export const apiGetListByBatchId = (params) => {
    return request.post(`/dky/predictCall/number/list?${qs.stringify(params, { indices: false })}`)
}
/* 启动外呼任务 */
export const apiStartCallList = (params) => {
    return request.post(`/dky/predictCall/start?${qs.stringify(params, { indices: false })}`)
}
/* 暂停外呼任务 */
export const apiPauseCallList = (params) => {
    return request.post(`/dky/predictCall/stop?${qs.stringify(params, { indices: false })}`)
}
/* 删除外呼任务 */
export const apiDelectCallList = (params) => {
    return request.post(`/dky/predictCall/delete?${qs.stringify(params, { indices: false })}`)
}


/* 订单 */
/* 新增订单 */
export const apiAddOrder = (params) => {
    return request.post('/back/order/add', params)
}
/* 订单删除 */
export const apiDeleteOrder = (params) => {
    return request.post('/back/order/delete', params)
}
/* 订单详情 */
export const apiOrderDetail = (params) => {
    return request.get('/back/order/detail', params)
}
/* 订单分页查询 */
export const apiGetOrderList = (params) => {
    return request.get('/back/order/pageList', params)
}
/* 学生订单集合 */
export const apiGetStudentOrderList = (params) => {
    return request.get('/back/order/orderList', params)
}
/* 修改订单 */
export const apiUpdateOrder = (params) => {
    return request.post('/back/order/update', params)
}
/* 签约学生分页查询 */
export const apiGetOrderSignList = (params) => {
    return request.get('/back/order/signing/pageList', params)
}

/* 兼职每日统计 */
export const apiGetParttimeStatistics = (params) => {
    return request.get('/back/sale/commissioner/parttimeStatisticsManager/pageList', params)
}


/* 兼职外呼列表 */
/* 获取学生详情 */
export const apiGetCallStudentDetail = (params) => {
    return request.get('/back/student/dial/record/detail', params)
}
/* 当前兼职所属销售拨打状态 */
export const apiGetSaleStatus = () => {
    return request.get('/back/student/dial/record/getSaleExtensionStatus')
}
/* 兼职外呼列表分页查询 */
export const apiGetAutoCallList = (params) => {
    return request.get('/back/student/dial/record/pageList', params)
}
/* 兼职我的有用分页查询 */
export const apiGetParttimeMyUsefulList = (params) => {
    return request.get('/back/student/dial/record/myUseful', params)
}
/* 新增学生信息 */
export const apiAutoCallAddStudent = (params) => {
    return request.post(`/back/student/dial/record/save?${qs.stringify(params, { indices: false })}`)
}
/* 转给销售 */
export const apiCallTransferredToSale = (params) => {
    return request.post(`/back/student/dial/record/transferredToSale?${qs.stringify(params, { indices: false })}`)
}
/* 修改外呼列表学生详情 */
export const apiUpdateAutoCallInfo = (params) => {
    return request.post('/back/student/dial/record/update/studentPool', params)
}
/* 修改外呼列表学生详情副本 */
export const apiUpdateAutoCallInfoCopy = (params) => {
    return request.post('/back/student/dial/record/update/studentInfo', params)
}

/* 销售专员接口 */
/* 删除/释放学生 id */
export const apiDelStuToStuPool = (params) => {
    return request.post(`/back/sale/commissioner/delStuToStuPool?${qs.stringify(params, { indices: false })}`)
}
/* 释放学生 studentId */
export const apiDelStuToStuPoolStu = (params) => {
    return request.post(`/back/sale/commissioner/delStuToStuPoolStu?${qs.stringify(params, { indices: false })}`)
}
/* 销售查看兼职意向分页查询 */
export const apiGetParttimeIntentionList = (params) => {
    return request.get('/back/sale/commissioner/parttimeIntention', params)
}
/* 发送给我 id */
export const apiParttimeSendToSale = (params) => {
    return request.post(`/back/sale/commissioner/sentToSale?${qs.stringify(params, { indices: false })}`)
}
/* 发送给我 studentId */
export const apiParttimeSendToSaleStu = (params) => {
    return request.post(`/back/sale/commissioner/sentToSaleStu?${qs.stringify(params, { indices: false })}`)
}
/* 获取兼职列表 */
export const apiGetParttimeList = () => {
    return request.get('/back/sale/commissioner/getParttimeList')
}

/* 销售我的意向分页查询 */
export const apiSaleGetMyIntentionList = (params) => {
    return request.get('/back/sale/commissioner/myIntention/pageList', params)
}
/* 销售释放学生到公海 */
export const apiSaleReleaseStudent = (params) => {  
    return request.post(`/back/sale/commissioner/releaseStudent?${qs.stringify(params, { indices: false })}`)
}
/* 销售新增默认有意向的学生 新增学生 */
export const apiSaleAddIntentionStudent = (params) => {
    return request.post('/back/sale/commissioner/save/intentionStudent', params)
}
/* 销售新增默认有意向的学生 新增学生 导入学生 批量导入学生 */
export const apiSaleBatchAddIntentionStudent = (file) => {
    let formData = new FormData()
    formData.append('files', file)
    return request.post("/back/sale/commissioner/saleImportFile", formData, {
        headers: {
            'Content-type': 'multipart/form-data'
        },
        timeout: 0,   // 导入文件超时时间
    })
}
/* 修改颜色 */
export const apiSaleUpdateColor = (params) => {
    return request.post(`/back/sale/commissioner/update/color?${qs.stringify(params, { indices: false })}`)
}
/* 销售申请添加学生到黑名单 */
export const apiSaleSetToBlackList = (params) => {
    return request.post(`/back/sale/commissioner/setToBlackList?${qs.stringify(params, { indices: false })}`)
}


/* 客户池发送短信 */
export const apiPoolSendMessage = (params) => {
    return request.post(`/back/student/pool/sendMessage?${qs.stringify(params, { indices: false })}`)
}
/* 销售短信模板 */
export const apiGetTextMessageList = () => {
    return request.get('/back/sale/TextMessage/getList')
}
/* 销售添加短信模板 */
export const apiSaveTextMessage = (params) => {
    return request.post(`/back/sale/TextMessage/save?${qs.stringify(params, { indices: false })}`)
}
/* 销售单个学生短信发送记录 */
export const apiGetStudentMessageList = (params) => {
    return request.get('/back/sale/TextMessage/sendMessageRecordList', params)
}

/* 销售公海分页查询 */
export const apiGetHigeSeasList = (params) => {
    return request.get("/back/sale/commissioner/highSeas/pageList", params)
}
/* 公海领取规则详情 */
export const apiGetSeaRulesDetail = () => {
    return request.get('/back/set/rules/getDetail')
}
/* 公海领取规则设置 */
export const apiSetSeaRules = (params) => {
    return request.post('/back/set/rules/saveOrUpdate', params)
}
/* 销售公海领取学生 */
export const apiSaleReceiveStudent = (params) => {
    return request.post(`/back/sale/commissioner/receiveStudent?${qs.stringify(params, { indices: false })}`)
}
/* 销售公海领取学生2 */
export const apiSaleReceiveStudentv2 = (params) => {
    return request.post(`/back/sale/commissioner/receiveStudentSeas?${qs.stringify(params, { indices: false })}`)
}
/* 公海销售集合（专员和兼职） */
export const apiGetSaleList = (params = {}) => {
    return request.get('/back/sale/commissioner/getSalesAndParttimeList', params)
}

/* 销售查看兼职分页统计 */
export const apiGetParttimeStatisticsList = (params) => {
    return request.get("/back/sale/commissioner/parttimeStatisticsCommiss/pageList", params)
}
/* 获取销售集合 销售列表 销售专员列表（只有专员） */
export const apiGetSaleStaffList = (params) => {
    return request.get('/back/sale/commissioner/getCurrentSalesList', params)
}
/* 获取销售部门列表 */
export const apiGetSaleOrgList = () => {
    // /sf/student/account/getSaleOrgList
    return request.get('/back/sale/commissioner/getSaleOrgList')
}
/* 获取销售专员列表 */
export const apiGetSfSaleStaffList = (params) => {
    return request.get("/sf/student/account/getSaleList", params)
}
/* 获取销售部门列表 */
export const apiGetSfSaleOrgList = () => {
    return request.get('/sf/student/account/getSaleOrgList')
}
/* 销售总监重新分配 */
export const apiRedistribution = (params) => {
    return request.post(`/back/sale/commissioner/redistribution?${qs.stringify(params, { indices: false })}`)
} 
/* 销售每日统计分页 */
export const apiGetSaleStatisticsList = (params) => {
    return request.get('/back/sale/commissioner/saleTodayStatistics/pageList', params)
}
/* 意向统计分页查询 */
export const apiGetIntentionStatisticsList = (params) => {
    return request.get('/back/sale/commissioner/intentionStatistics/pageList', params)
}
/* 订单/签约统计分页 */
export const apiGetOrderStatisticsList = (params) => {
    return request.get('/back/sale/commissioner/orderStatistics/pageList', params)
}


/* 根据年级位置批次获取学生 */
export const apiGetStudentsForMessage = (params) => {
    return request.get('/back/student/pool/getStudentsByMessage', params)
}

/* 上一个下一个学生id */
export const apiGetUpAndNextIds = (params) => {
    return request.get("/back/sale/commissioner/getUpAndNextIds", params)
}



/* 获取首次手拨拨据领取详情 */
export const apiGetFirstGetRuleDetail = () => {
    return request.get("/back/set/receive/rules/getDetailHand")
}
/* 获取真实数据详情 */
export const apiGetSuccessGetRuleDetail = () => {
    return request.get("/back/set/receive/rules/getDetailGroup")
}
/* 新增/修改首次手拨领取规则 */
export const apiUpdateFirstGetRule = (params) => {
    return request.post("/back/set/receive/rules/saveOrUpdateHand", params)
}
/* 新增/修改真实数据领取详情 */
export const apiUpdateSuccessGetRule = (params) => {
    return request.post("/back/set/receive/rules/saveOrUpdateGroup", params)
}
/* 获取超过多少次进入黑名单详情 */
export const apiGetBlackRuleDetail = () => {
    return request.get("/back/set/receive/rules/getDetailBlackList")
}
/* 获取系统拨打时间详情 */
export const apiGetSysCallTimeDetail = () => {
    return request.get("/back/set/receive/rules/getDetailTime")
}
/* 新增/修改超过次数进入黑名单 */
export const apiUpdateBlackRule = (params) => {
    return request.post("/back/set/receive/rules/saveOrUpdateBlackList", params)
}
/* 新增修改系统拨打时间详情 */
export const apiUpdateSysCallTime = (params) => {
    return request.post("/back/set/receive/rules/saveOrUpdateTime", params)
}
/* 获取中转站到真实数据池天数详情 */
export const apiGetTransferToSuccessRule = (params) => {
    return request.get('/back/set/receive/rules/getDetailAllowPool', params)
}
/* 新增/修改中转站到真实数据池天数 */
export const apiUpdateTransferToSuccessRule = (params) => {
    return request.post("/back/set/receive/rules/saveOrUpdateAllowPool", params)
}
/* 真实数据查询 */
export const apiGetSuccessPoolList = (params) => {
    return request.get("/back/hand/call/allocationData/pageList", params)
}
/* 检测数据 */
export const apiChackData = (file) => {
    let formData = new FormData()
    formData.append('files', file)
    return request.post("/back/hand/call/checkData", formData, {
        headers: {
            'Content-type': 'multipart/form-data'
        },
        timeout: 0,   // 导入文件超时时间
    })
}
/* 创建手拨 */
export const apiCreateFirstHandCall = (params) => {
    return request.post(`/back/hand/call/createHandCall?${qs.stringify(params, { indices: false })}`)
}
/* 真实数据领取列表 */
export const apiGetSuccessHandList = (params) => {
    return request.get("/back/hand/call/groupReceive/pageList", params)
}
/* 未回收数据列表 */
export const apiGetNoRecoveryList = (params) => {
    return request.get("/back/hand/call/noRecovery/pageList", params)
}
/* 首日拨打池子数据列表 */
export const apiGetFirstPoolList = (params) => {
    return request.get("/back/hand/call/phone/pageList", params)
}
/* 领取数据 */
export const apiSaveGetHandData = (params) => {
    return request.post(`/back/hand/call/receive?${qs.stringify(params, { indices: false })}`)
}
/* 首次拨打领取列表 */
export const apiGetFirstHandList = (params) => {
    return request.get("/back/hand/call/receive/pageList", params)
}
/* 中转站列表 */
export const apiGetTransferStationList = (params) => {
    return request.get("/back/hand/call/transferStation/pageList", params)
}
/* 回到真实数据 */
export const apiTransferToSuccess = (params) => {
    return request.post(`/back/hand/call/goToAllow?${qs.stringify(params, { indices: false })}`)
}
/* 回到真实数据复制 */
export const apiTransferToSuccessCopy = (params) => {
    return request.post(`/back/hand/call/goToRelease?${qs.stringify(params, { indices: false })}`)
} 
/* 首次拨打上一个下一个 */
export const apiGetSaleFirstPrevAndNext = (params) => {
    return request.get("/back/hand/call/receive/getUpAndNextIds", params)
}
/* 首次拨打领取列表 */
export const apiGetSaleGetFirstList = (params) => {
    return request.get("/back/hand/call/receive/pageList", params)
}
/* 真实数据上一个下一个 */
export const apiGetSaleSuccessPrevAndNext = (params) => {
    return request.get("/back/hand/call/saleCanAllow/getUpAndNextIds", params)
}
/* 真实数据领取列表 */
export const apiGetSaleGetSuccessList = (params) => {
    return request.get("/back/hand/call/saleCanAllow/pageList", params)
}
/* 获取加入有用规则 */
export const apiGetAddUseDetail = () => {
    return request.get('/back/set/receive/rules/getDetailUserful')
}
/* 更新加入有用规则 */
export const apiUpdateAddUseRule = (params) => {
    return request.post("/back/set/receive/rules/saveOrUpdateUserful", params)
}
/* 获取首次拨打领取详情/获取真实数据领取详情 */
export const apiGetGetUserDetail = (params) => {
    return request.get("/back/hand/call/getUserCallNum", params)
}

/* 获取销售业绩列表 */
export const apiGetSalePerformanceList = (params) => {
    return request.get("/back/set/target/sale/pageList", params)
}
/* 保存销售业绩目标 */
export const apiSaveSalePerformance = (params) => {
    return request.post("/back/set/target/sale/save", params)
}
/* 更新销售业绩目标 */
export const apiUpdateSalePerformance = (params) => {
    return request.post("/back/set/target/sale/update", params)
}
/* 删除销售业绩目标 */
export const apiDeleteSalePerformance = (params) => {
    return request.post(`/back/set/target/sale/delete?${qs.stringify(params, { indices: false })}`)
}
/* 获取销售目标业绩详情 */
export const apiGetSalePerformanceDetail = (params) => {
    return request.get("/back/set/target/sale/getDetail", params)
}

/* 销售主管-本周报表 */
export const apiGetSaleWeekReport = (params) => {
    return request.get("/back/sale/commissioner/getSaleReportFormsList", params)
}

/* 是否到访 */
export const apiSaleUpdateIsVisit = (params) => {
    return request.post(`/back/sale/commissioner/update/visit?${qs.stringify(params, { indices: false })}`)
}
/* 今日概览-公司部门排行榜 */
export const apiGetCompanySectionRank = (params) => {
    return request.get("/back/sale/board/statistics/getChartsList", params)
}
/* 今日概览-漏斗图 */
export const apiSaleGetMyPerformance = (params) => {
    return request.get("/back/sale/board/statistics/getFunnelPlot", params)
}
/* 今日概览-本月业绩 */
export const apiSaleGetMonthPerformance = (params) => {
    return request.get("/back/sale/board/statistics/getMonthPerformance", params)
}
/* 今日概览-业绩趋势饼状图 */
export const apiSaleGetPerformancePieTrend = (params) => {
    return request.get("/back/sale/board/statistics/getPerformanceTrendCake", params)
}
/* 今日概览-业绩趋势折线图 */
export const apiSaleGetPerformanceLineTrend = (params) => {
    return request.get("/back/sale/board/statistics/getPerformanceTrendLine", params)
}
/* 今日概览-今日数据 */
export const apiSaleGetTodayData = (params) => {
    return request.get("/back/sale/board/statistics/getSaleData", params)
}
/* 今日概览-本月任务 */
export const apiSaleGetMonthTask = (params) => {
    return request.get("/back/sale/board/statistics/getTask", params)
}
/* 今日概览-通话数据 */
export const apiGetCallTaskData = (params) => {
    return request.get("/back/sale/board/statistics/getCallTask", params)
}
/* 坐席监控 */
/* 监控列表 */
export const apiGetSaleCallList = (params) => {
    return request.get("/back/sale/commissioner/getSeatMonitorInfo", params)
}
/* 坐席退出登录 */
export const apiSetSaleCallLogout = (params) => {
    return request.post(`/back/sale/commissioner/agentLogout?${qs.stringify(params, { indices: false })}`)
}
/* 坐席置忙置闲 */
export const apiSetSaleCallState = (params) => {
    return request.post("/back/sale/commissioner/updateAgentState", params)
}
/* 坐席启用禁用 */
export const apiSetSaleIsCanCall = (params) => {
    return request.post(`/back/sale/commissioner/setDkyAgentType?${qs.stringify(params, { indices: false })}`)
}

/* 保存手拨通话记录 */
export const apiSaveSaleCallRecord = (params) => {
    return request.post("/back/hand/call/saveUserCallRecord", params)
}

/* 员工拨打记录列表 */
export const apiGetCallHistoryList = (params) => {
    return request.get("/back/hand/call/userCallRecord/pageList", params)
}

/* 加入黑名单运营商 */
export const apiCallSysAddBlack = (params) => {
    return request.post(`/back/student/pool/addBlackListOperator?${qs.stringify(params, { indices: false })}`)
}

/* 设置提醒天数 */
export const apiSetRemindRule = (params) => {
    return request.post("/back/set/receive/rules/saveOrUpdateRemindRule", params)
}
/* 点控云 */
/* 新增坐席 */
export const apiAddCallAccount = (params) => {
    return request.post("/back/callerIdNum/save", params)
}
/* 修改坐席 */
export const apiUpdateCallAccount = (params) => {
    return request.post("/back/callerIdNum/updateById", params)
}
/* 删除坐席 */
export const apiDeleteCallAccount = (params) => {
    return request.post(`/back/callerIdNum/remove?${qs.stringify(params, { indices: false })}`)
}
/* 坐席列表 */
export const apiGetCallAccountList = (params) => {
    return request.get("/back/callerIdNum/pageList", params)
}
/* 分配 */
export const apiAllocCallAccount = (params) => {
    return request.post("/back/callerIdNum/allowUser", params)
}
/* 优音云坐席 */
/* 新增坐席 */
export const apiAddYYYCallAccount = (params) => {
    return request.post("/back/callerIdNum/yyy/save", params)
}
/* 修改坐席 */
export const apiUpdateYYYCallAccount = (params) => {
    return request.post("/back/callerIdNum/yyy/updateById", params)
}
/* 删除坐席 */
export const apiDeleteYYYCallAccount = (params) => {
    return request.post(`/back/callerIdNum/yyy/remove?${qs.stringify(params, { indices: false })}`)
}
/* 坐席列表 */
export const apiGetYYYCallAccountList = (params) => {
    return request.get("/back/callerIdNum/yyy/pageList", params)
}
/* 分配 */
export const apiAllocYYYCallAccount = (params) => {
    return request.post("/back/callerIdNum/yyy/allowUser", params)
}

/* 数企坐席 */
/* 新增坐席 */
export const apiAddSQCallAccount = (params) => {
    return request.post("/back/callerIdNum/sq/save", params)
}
/* 修改坐席 */
export const apiUpdateSQCallAccount = (params) => {
    return request.post("/back/callerIdNum/sq/updateById", params)
}
/* 删除坐席 */
export const apiDelectSQCallAccount = (params) => {
    return request.post(`/back/callerIdNum/sq/remove?${qs.stringify(params, { indices: false })}`)
}
/* 坐席列表 */
export const apiGetSQCallAccountList = (params) => {
    return request.get("/back/callerIdNum/sq/pageList", params)
}
/* 分配 */
export const apiAllocSQCallAccount = (params) => {
    return request.post("/back/callerIdNum/sq/allowUser", params)
}

/* 连连坐席 */
/* 新增坐席 */
export const apiAddLLCallAccount = (params) => {
    return request.post("/back/callerIdNum/lxy/save", params)
}
/* 修改坐席 */
export const apiUpdateLLCallAccount = (params) => {
    return request.post("/back/callerIdNum/lxy/updateById", params)
}
/* 删除坐席 */
export const apiDelectLLCallAccount = (params) => {
    return request.post(`/back/callerIdNum/lxy/remove?${qs.stringify(params, { indices: false })}`)
}
/* 坐席列表 */
export const apiGetLLCallAccountList = (params) => {
    return request.get("/back/callerIdNum/lxy/pageList", params)
}
/* 分配 */
export const apiAllocLLCallAccount = (params) => {
    return request.post("/back/callerIdNum/lxy/allowUser", params)
}

/* 千创云坐席 */
/* 新增坐席 */
export const apiAddQCYCallAccount = (params) => {
    return request.post("/back/callerIdNum/qcy/save", params)
}
/* 修改坐席 */
export const apiUpdateQCYCallAccount = (params) => {
    return request.post("/back/callerIdNum/qcy/updateById", params)
}
/* 删除坐席 */
export const apiDelectQCYCallAccount = (params) => {
    return request.post(`/back/callerIdNum/qcy/remove?${qs.stringify(params, { indices: false })}`)
}
/* 坐席列表 */
export const apiGetQCYCallAccountList = (params) => {
    return request.get("/back/callerIdNum/qcy/pageList", params)
}
/* 分配 */
export const apiAllocQCYCallAccount = (params) => {
    return request.post("/back/callerIdNum/qcy/allowUser", params)
}

/* 获取坐席详情 点控云 */
export const apiGetCallAcountDetail = (params) => {
    return request.get("/back/callerIdNum/getDetailById", params)
}
/* 获取坐席详情 优音云 */
export const apiGetYYYCallAcountDetail = (params) => {
    return request.get("/back/callerIdNum/yyy/getDetailById", params)
}
/* 获取坐席详情 数企 */
export const apiGetSQCallAccountDetail = (params) => {
    return request.get("/back/callerIdNum/sq/getDetailById", params)
}
/* 获取坐席详情 连连 */
export const apiGetLLCallAccountDetail = (params) => {
    return request.get("/back/callerIdNum/lxy/getDetailById", params)
}
/* 获取坐席详情 千创云 */
export const apiGetQCYCallAccountDetail = (params) => {
    return request.get("/back/callerIdNum/qcy/getDetailById", params)
}


/* 重新分配学生给销售 */
export const apiAllocStudentToSale = (params) => {
    return request.post("/back/student/pool/reAllocationStudent", params)
}
/* 获取数据管理员导入的批次列表 */
export const apiGetHandBatchRecordList = (params) => {
    return request.get("/back/student/pool/getBatchRecordList", params)
}
/* 手拨列表退回 */
export const apiHandListSendBack = () => {
    return request.post("/back/hand/call/allSendBack")
}

/* 谈单反馈 */
/* 详情 */
export const apiGetNegotiationOrderDetail = (params) => {
    return request.get("/back/negotiation/order/getInfo", params)
}
/* 新增 */
export const apiAddNegotiationOrder = (params) => {
    return request.post("/back/negotiation/order/saveInfo", params)
}

/* 获取年级分布 */
export const apiGetCallSectionDetail = (params) => {
    return request.get("/back/student/pool/section/distribution", params)
}

/* 谈单分析相关 */
/* 概览 */
export const apiGetNegotiationOrderOverviewList = (params) => {
    return request.get("/back/sale/commissioner/negotiationOrderOverview", params)
}
/* 详情 */
export const apiGetNegotiationOrderDetailList = (params) => {
    return request.get("/back/sale/commissioner/negotiationOrderDetail", params)
}
/* 助力名单 */
export const apiGetNegotiationOrderSale = (params) => {
    return request.get("/back/sale/commissioner/getSaleAssistanceNameList", params)
}
/* 跟单过程 */
export const apiGetNegotiationOrderProcess = (params) => {
    return request.get("/back/sale/commissioner/getSaleNegotiationOrderProcess", params)
}

/* 获取兼职拨打记录 */
export const apiGetParttimeCallHistoryList = (params) => {
    return request.get("/back/student/pool/parttime/callLog/pageList", params)
}
/* 专员发送兼职的学生给自己 */
export const apiSaleStaffAddIntention = (params) => {
    return request.post(`/back/student/pool/addIsIntention?${qs.stringify(params, { indices: false })}`)
}


/* 签约学生分页 */
export const apiGetSignStudentList = (params) => {
    return request.get("/sf/student/pool/signStudent/pageList", params)
}


/* 获取最新一条录音 */
export const apiGetLastCallRecord = (params) => {
    return request.get("/back/student/pool/getFboccRecID", params)
}

/* 获取用户公海新增数量 */
export const apiGetUserSeasNum = (params) => {
    return request.get("/sys/user/getUserHighSeasNum", params)
}

/* 用户公海数量清零 */
export const apiUserSeasNumSetZero = (params) => {
    return request.post(`/sys/user/userHighSeasNumZero?${qs.stringify(params, { indices: false })}`)
}
/* 获取销售个人库释放时间详情 */
export const apiGetPersonalStoreDetail = () => {
    return request.get("/back/student/personal/getDetailPersonalStore")
}
/* 设置销售个人库释放时间 */
export const apiSetPersonalStore = (params) => {
    return request.post("/back/student/personal/saveOrUpdatePersonalStore", params)
}
/* 导入个人库学生 */
export const apiImportPersonalStoreFile = (file) => {
    let formData = new FormData()
    formData.append('files', file)
    return request.post("/back/student/personal/importFile", formData, {
        headers: {
            'Content-type': 'multipart/form-data'
        },
        timeout: 0,   // 导入文件超时时间
    })
}
/* 获取销售个人库列表 */
export const apiGetPersonalList = (params) => {
    return request.get("/back/student/personal/pageList", params)
}
/* 销售个人库列表上一个下一个 */
export const apiGetPersonalPrevAndNextIds = (params) => {
    return request.get("/back/student/personal/pageList/getUpAndNextIds", params)
}
/* 释放学生到手拨池子 */
export const apiRealeaseStudentToHandCall = (params) => {
    return request.post(`/back/student/personal/releaseStudentHandCall?${qs.stringify(params, { indices: false })}`)
}
/* 销售新增个人库学生 */
export const apiAddPersonalStudent = (params) => {
    return request.post("/back/student/personal/save/personalStudent", params)
}

/* 真实数据池子年级分布 */
export const apiGetCallPollSectionInfo = () => {
    return request.get("/back/hand/call/allocationData/section/distribution")
}

/* 修改批次标题 */
export const apiEditStudentBatchInfo = (params) => {
    return request.post("/back/student/pool/updateStudentBatchRecord", params)
}
/* 获取销售专员兼职教务专员列表 */
export const apiGetStaffAndParttimeAndEduList = (params) => {
    return request.get("/back/student/pool/getUserList", params)
}

/* 获取毕业生列表 */
export const apiGetBiYeShengList = (params) => {
    return request.get("/back/student/pool/biyesheng/pageList", params)
}

/* 销售打电话平台设置 */
export const apiSetCallPlatform = (params) => {
    return request.post("/sys/user/setCallPlatform", params)
}

/* 获取优音云坐席状态 */
export const apiGetYYYAgentStatus = (params) => {
    return request.get("/yyy/getMonitorCTIStatus", params)
}

/* 获取学生详情，通过学生手机号码获取 */
export const apiGetStudentDetailByAccount = (params) => {
    return request.get("/back/student/pool/getDetailByAccount", params)
}

/* 获取坐席外显号码 */
export const apiGetCallAccountShowNum = (params) => {
    return request.get("/yyy/getMemo", params)
}

/* 设置坐席外显号码 */
export const apiSetCallAccountShowNum = (params) => {
    return request.get("/yyy/setMemo", params)
}

/* 电话设置-获取销售和兼职列表 */
export const apiGetSaleAndParttimePageList = (params) => {
    return request.get("/back/student/pool/getSaleAndParttime/pageList", params)
}
/* 禁止轮转分页查询 */
export const apiGetNoWanderStuList = (params) => {
    return request.get("/back/student/pool/noWander/pageList", params)
}

/* 交接单相关 */
/* 修改交接单 */
export const apiEditReceipt = (params) => {
    return request.post("/back/student/delivery/receipt/edit", params)
}
/* 获取交接单详情 */
export const apiGetReceiptDetail = (params) => {
    return request.get("/back/student/delivery/receipt/getDetail", params)
}
/* 删除交接单 */
export const apiDeleteReceipt = (params) => {
    return request.get(`/back/student/delivery/receipt/remove?${qs.stringify(params, { indices: false })}`)
}
/* 退回交接单 */
export const apiGoBackReceipt = (params) => {
    return request.post(`/back/student/delivery/receipt/goBack?${qs.stringify(params, { indices: false })}`)
}

/* 销售转部门 */
export const apiChangeSaleOrg = (params) => {
    return request.post("/sys/user/updateUserOrg", params)
}

/* 拨打记录统计 */
export const apiGetCallRecordCount = (params) => {
    return request.get("/back/hand/call/getCallRecordCount", params, {
        timeout: 6 * 60 * 1000
    })
}

/* 意向列表置顶 */
export const apiSetIntentionIsTop = (params) => {
    return request.post(`/back/sale/commissioner/update/top?${qs.stringify(params, { indices: false })}`)
}
/* 销售拨打公海列表 */
export const apiGetSaleCallSeaList = (params) => {
    return request.get("/back/hand/call/personSeas/pageList", params)
}
/* 销售拨打公海列表下一个上一个 */
export const apiCallSeaGetUpAndNextIds = (params) => {
    return request.get("/back/hand/call/personSeas/getUpAndNextIds", params)
}

/* 销售总监意向 */
export const apiGetIntentionCountData = (params) => {
    return request.get("/back/sale/commissioner/getIntentionCount", params)
}

/* 通知 */
/* 通知详情 */
export const apiGetNoticeDetail = (params) => {
    return request.get("/sys/notice/getDetail", params)
}
/* 通知列表 */
export const apiGetNoticeList = (params) => {
    return request.get("/sys/notice/pageList", params)
}
/* 删除通知 */
export const apiDeleteNotice = (params) => {
    return request.post(`/sys/notice/remove?${qs.stringify(params, { indices: false })}`)
}
/* 新增通知 */
export const apiAddNotice = (params) => {
    return request.post("/sys/notice/save", params)
}
/* 设置已读 */
export const apiSetNoticeRead = (params) => {
    return request.post(`/sys/notice/setUserRead?${qs.stringify(params, { indices: false })}`)
}
/* 通知编辑 */
export const apiUpdateNotice = (params) => {
    return request.post("/sys/notice/update", params)
}


/* 数据管理员-公海-销售领取列表 */
export const apiGetPersonSeasList = (params) => {
    return request.get("/back/hand/call/personSeas/major/pageList", params)
}

/* 销售总监-拨打记录详情 */
export const apiGetPersonCallRecordDetail = (params) => {
    return request.get("/back/hand/call/getCallRecordDetail", params, {
        timeout: 6 * 60 * 1000
    })
}


/* 成功案例 */
/* 新增成功案例 */
export const apiAddSuccessCase = (params) => {
    return request.post("/back/student/success/case/save", params)
}
/* 删除成功案例 */
export const apiDeleteSuccessCase = (params) => {
    return request.post(`/back/student/success/case/remove?${qs.stringify(params, { indices: false })}`)
}
/* 获取成功案例列表 */
export const apiGetSuccessCaseList = (params) => {
    return request.get("/back/student/success/case/pageList", params)
}
/* 获取成功案例详情 */
export const apiGetSuccessCaseDetail = (params) => {
    return request.get(`/back/student/success/case/getDetail?${qs.stringify(params, { indices: false })}`)
}
/* 编辑成功案例 */
export const apiEditSuccessCase = (params) => {
    return request.post("/back/student/success/case/edit", params)
}

/* 销售退费信息设置接口 */
export const apiSaleSetRefundInfo = (params) => {
    return request.post("/back/sale/performance/statistics/setRefundInfo", params)
}

/* 数据管理池各个位置数据分布 */
export const apiGetAllPositionData = () => {
    return request.get("/back/student/pool/getTotalPhonePoolCount")
}

/* 签约列表详情-从数据统计中进入 */
export const apiGetSignListByBatch = (params) => {
    return request.get("/back/sale/commissioner/contractDetail/pageList", params)
}


/* 发送短信详情 */
/* 发送短信记录 */
export const apiGetSendMsgRecord = (params) => {
    return request.get("/back/send/message/smsRecordPageList", params)
}
/* 发短信电话池数据 */
export const apiGetSendPoolList = (params) => {
    return request.get("/back/send/message/phonePool/pageList", params)
}
/* 自定义短信发送 */
export const apiSendSelfMsg = (params) => {
    return request.post("/back/send/message/sendSms", params)
}
/* 取消定时短信 */
export const apiCancelTimerSendMsg = (params) => {
    return request.post(`/back/send/message/smsCancelTiming?${qs.stringify(params, { indices: false })}`)
}
/* 新增模板 */
export const apiAddMsgTemplate = (params) => {
    return request.post("/back/send/message/templateReport", params)
}
/* 删除模板 */
export const apiDelectMsgTemplate = (params) => {
    return request.post(`/back/send/message/templateDelete?${qs.stringify(params, { indices: false })}`)
}
/* 获取模板列表 */
export const apiGetMsgTemplateList = (params) => {
    return request.get("/back/send/message/templatePageList", params)
}
/* 短信模板列表不分页 */
export const apiGetMsgTemplateListNoPage = () => {
    return request.get("/back/send/message/templateList")
}
/* 新增签名 */
export const apiAddMsgSign = (params) => {
    return request.post("/back/send/message/signReport", params)
}
/* 删除签名 */
export const apiDelectMsgSign = (params) => {
    return request.post(`/back/send/message/signDelete?${qs.stringify(params, { indices: false })}`)
}
/* 获取签名列表 */
export const apiGetMsgSignList = (params) => {
    return request.get("/back/send/message/signPageList", params)
}
/* 获取签名列表 不分页*/
export const apiGetMsgSignListNoPage = () => {
    return request.get("/back/send/message/signList")
}

/* 获取短信回复列表 */
export const apiGetMsgReplyList = (params) => {
    return []
    return request.get("", params)
}

/* 发送短信成功失败信息 */
export const apiGetMsgSendNumInfo = (params) => {
    return request.get("/back/send/message/getNumByMsgId", params)
}


/* 确定批次数据分配给销售 */
export const apiSaveImportBatchAlloc = (params) => {
    return request.post("/back/student/pool/poolToSales", params)
}
/* 根据批次id获取可分配数据列表 */
export const apiGetBatchStudentAllocList = (params) => {
    return request.get("/back/student/pool/poolBatchDetail/pageList", params)
}


/* 推广相关 */
/* 推广数据导入分页查询 */
export const apiGetOtherSourceImportList = (params) => {
    return request.get("/back/student/pool/promotion/batchRecord/pageList", params)
}
/* 推广数据导入查询不分页 */
export const apiGetOtherSourceImportListNoPage = () => {
    return request.get("/back/student/pool/promotion/batchRecordList")
}
/* 推广数据详情分页查询 */
export const apiGetOtherSourceImportDetail = (params) => {
    return request.get("/back/student/pool/promotionDetail/pageList", params)
}
/* 重复数据详情分页查询 */
export const apiGetOtherSourceRepeatDetail = (params) => {
    return request.get("/back/student/pool/repeatDetail/pageList", params)
}
/* 推广数据分配给销售 */
export const apiOtherSourceAllocToSale = (params) => {
    return request.post("/back/student/pool/allocateToSales", params)
}
/* 推广数据导入 */
export const apiOtherSourceImport = (params, file) => {
    let formData = new FormData()
    formData.append('files', file)
    return request.post(`/back/student/pool/promotionImportFile?${qs.stringify(params, { indices: false })}`, formData, {
        headers: {
            'Content-type': 'multipart/form-data'
        },
        timeout: 0,   // 导入文件超时时间
    })
}

/* 推广规则详情 */
export const apiGetOtherSourceRuleDetail = () => {
    return request.get("/back/student/pool/getPromotionRules")
}
/* 新增推广规则 */
export const apiSaveOtherSourceRule = (params) => {
    return request.post("/back/student/pool/savePromotionRules", params)
}

/* 销售领取其他来源列表 */
export const apiGetSaleOtherSourceList = (params) => {
    return request.get("/back/student/pool/otherSources/pageList", params)
}
/* 其他来源上一个下一个 */
export const apiGetOtherSourceUpAndNextIds = (params) => {
    return request.get("/back/student/pool/getOtherSourcesUpAndNextIds", params)
}
/* 修改其他来源类别 */
export const apiEditOtherSourceType = (params) => {
    return request.post("/back/student/pool/updateOtherSources", params)
}

/* 批次展开 */
export const apiGetBatchExpandList = (params) => {
    return request.get("/back/student/pool/batchExpandList", params)
}

/* 学校管理相关 */
/* 添加学校简称 */
export const apiAddSchoolShortName = (params) => {
    return request.post(`/back/school/addShortName?${qs.stringify(params, { indices: false })}`)
}
/* 学校详情 */
export const apiGetSchoolDetail = (params) => {
    return request.get("/back/school/getDetailById",  params)
}
/* 学校列表 */
export const apiGetSchoolListNoPage = (params) => {
    return request.get("/back/school/getList", params)
}
/* 导入学校 */
export const apiImportSchoolFile = (file) => {
    let formData = new FormData()
    formData.append('files', file)
    return request.post("/back/school/importFile", formData, {
        headers: {
            'Content-type': 'multipart/form-data'
        },
        timeout: 0,   // 导入文件超时时间
    })
}
/* 学校分页查询 */
export const apiGetSchoolList = (params) => {
    return request.get("/back/school/pageList", params)
}
/* 删除学校 */
export const apiDeleteSchool = (params) => {
    return request.post(`/back/school/remove?${qs.stringify(params, { indices: false })}`)
}
/* 新增学校 */
export const apiAddSchool = (params) => {
    return request.post("/back/school/save", params)
}
/* 修改学校 */
export const apiEditSchool = (params) => {
    return request.post("/back/school/update", params)
}

/* 获取录音列表 */
export const apiGetLocalAudioRecord = (params) => {
    return request.get("/back/student/pool/callLogLocal/pageList", params)
}

/* 录音点评详情中操作相关 */
/* 获取当前人未读点评数据 */
export const apiSxyNewNoReadNum = () => {
    return request.get("/back/recording/learn/getNoReadNum")
}
/* 删除录音点评（选择文字点评这种） */
export const apiSxyDeleteSelectTxtComment = (params) => {
    return request.post(`/back/recording/reply/delCommentDetail?${qs.stringify(params, { indices: false })}`)
}
/* 新增录音点评（选择文字点评） */
export const apiSxyAddSelectTxtComment = (params) => {
    return request.post("/back/recording/reply/saveCommentDetail", params)
}
/* 修改录音点评（选择文字点评这种）  */
export const apiSxyEditSelectTxtComment = (params) => {
    return request.post("/back/recording/reply/saveCommentDetail", params)
}
/* 新增录音点评（整个录音点评） */
export const apiSxyAddAudioComment = (params) => {
    return request.post("/back/recording/reply/saveComment", params)
}
/* 修改录音点评（整个录音点评） */
export const apiSxyEditAudioComment = (params) => {
    return request.post("/back/recording/reply/updateComment", params)
}
/* 删除录音点评（整个录音点评） */
export const apiSxyDeleteAudioComment = (params) => {
    return request.post(`/back/recording/reply/delComment?${qs.stringify(params, { indices: false })}`)
}


/* 删除录音点评上传文件 */
export const apiSxyDeleteAudioCommentFile = (params) => {
    return request.post(`/back/recording/reply/delReplyFile?${qs.stringify(params, { indices: false })}`)
}
/* 新增录音点评上传文件 */
export const apiSxyAddAudioCommentFile = (params) => {
    return request.post("/back/recording/reply/saveReplyFile", params)
}
/* 修改录音点评上传文件 */
export const apiSxyEditAudioCommentFile = (params) => {
    return request.post("/back/recording/reply/updateReplyFile", params)
}


/* 是否可以修改点评 */
export const apiSxyIsCanEditAudioComment = (params) => {
    return request.post(`/back/recording/reply/setIsCanUpdateComment?${qs.stringify(params, { indices: false })}`)
}

/* 录音学习 */
/* 添加求点评内容 */
export const apiSxyAddRequestContent = (params) => {
    return request.post(`/back/recording/learn/addRequestCommentContent?${qs.stringify(params, { indices: false })}`)
}
/* 删除未发送的录音 */
export const apiSxyDeleteNotSendAudio = (params) => {
    return request.post(`/back/recording/learn/delById?${qs.stringify(params, { indices: false })}`)
}
/* 撤销点评 */
export const apiSxyRevokeAudioComment = (params) => {
    return request.post(`/back/recording/learn/revokeComment?${qs.stringify(params, { indices: false })}`)
}
/* 转发录音学习 */
export const apiSxyTransAudioLearn = (params) => {
    return request.post("/back/recording/learn/saveForward", params)
}
/* 上传需要点评的录音 */
export const apiSxyUploadLearnAudio = (params) => {
    return request.post("/back/recording/learn/uploadRecording", params)
}
/* 管理员直接发送到点评 */
export const apiSxyDirectSendToComment = (params) => {
    return request.post("/back/recording/learn/sendAndComment", params)
}
/* 录音学习分页查询 */
export const apiSxyGetAudioLearnList = (params) => {
    return request.get("/back/recording/learn/pageList", params)
}



/* 录音点评操作相关 */
/* 录音转文字 */
export const apiSxyAudioTransTxt = (params) => {
    return request.post(`/back/recording/reply/recordingConvertText?${qs.stringify(params, { indices: false })}`)
}
/* 修改录音转文字内容 */
export const apiSxyEditAudioTxt = (params) => {
    return request.post("/back/recording/reply/updateRecordingContent", params)
}
/* 查询录音转文字状态 */
export const apiSxyGetAudioTransTxtStatus = (params) => {
    return request.post(`/back/recording/learn/getConvertTextStatus?${qs.stringify(params, { indices: false })}`)
}
/* 录音点评-删除 */
export const apiSxyDeleteCommentAudio = (params) => {
    return request.post(`/back/recording/learn/adminDelById?${qs.stringify(params, { indices: false })}`)
}
/* 录音点评-列表 */
export const apiSxyGetAudioCommentList = (params) => {
    return request.get("/back/recording/reply/pageList", params)
}
/* 录音点评-发送商学院 */
export const apiSxySendAudioCommentToStudy = (params) => {
    return request.post(`/back/recording/reply/addRequestCommentContent?${qs.stringify(params, { indices: false })}`)
}
/* 录音点评-获取详情 */
export const apiSxyGetAudioCommentDetail = (params) => {
    return request.get("/back/recording/learn/getCommentDetailById", params)
}



/* 标签相关 */
/* 录音点评-总标签管理-获取标签详情 */
export const apiSxyGetAudioLableManageDetail = (params) => {
    return request.get("/back/label/getDetail", params)
}
/* 录音点评-总标签管理-标签管理保存 */
export const apiSxySaveAudioLableManage = (params) => {
    return request.post(`/back/label/save?${qs.stringify(params, { indices: false })}`)
}
/* 录音点评-总标签管理-修改标签 */
export const apiSxyAudioLabelManageEdit = (params) => {
    return request.post(`/back/label/update?${qs.stringify(params, { indices: false })}`)
} 
/* 新增录音转文字权限 */
export const apiSxySaveConvertTextRole = (params) => {
    return request.post(`/back/label/saveConvertTextRole?${qs.stringify(params, { indices: false })}`)
}
/* 获取录音转文字权限 */
export const apiSxyGetConvertTextRoleDetail = () => {
    return request.get("/back/label/getConvertTextRoleDetail")
}
/* 新增点评可设置标签权限 */
export const apiSxySaveTagRole = (params) => {
    return request.post(`/back/label/saveTagRole?${qs.stringify(params, { indices: false })}`)
}
/* 获取点评可设置标签权限 */
export const apiSxyGetTagRoleDetail = () => {
    return request.get("/back/label/getTagRoleDetail")
}

/* 点评录音-保存标签 */
export const apiSxySaveCommentTag = (params) => {
    return request.post(`/back/recording/reply/updateCommentTag?${qs.stringify(params, { indices: false })}`)
}
/* 获取销售主管和销售专员 */
export const apiGetSaleLeaderAndStaffList = () => {
    return request.post("/back/recording/learn/getOrgUserList")
}
/* 修改录音点评 */
export const apiSxyEditAllAudioComment = (params) => {
    return request.post("/back/recording/learn/updateRecording", params)
}
/* 录音点评调用列表 */
export const apiSxyGetAudioInvokingFileList = (params) => {
    return request.get("/back/recording/learn/getInvokingMaterialList", params)
}
/* 录音点评页面直接上传录音 */
export const apiSxyUploadAudioComment = (params) => {
    return request.post("/back/recording/learn/directUploadRecording", params)
}

/* 商学院-录音学习-管理端 */
/* 录音管理分页查询 */
export const apiSxyBackGetAudioManageList = (params) => {
    return request.get("/back/business/school/recording/pageList", params)
}
/* 设置录音类别 */
export const apiSxyBackSetAudioCategory = (params) => {
    return request.post(`/back/business/school/setRecordingCategory?${qs.stringify(params, { indices: false })}`)
}
/* 设置录音上架下架 */
export const apiSxyBackSetAudioIsShelves = (params) => {
    return request.post(`/back/business/school/setRecordingShelves?${qs.stringify(params, { indices: false })}`)
}
/* 设置录音置顶 */
export const apiSxyBackSetAudioIsTop = (params) => {
    return request.post(`/back/business/school/setRecordingTop?${qs.stringify(params, { indices: false })}`)
}

/* 删除录音评价 */
export const apiSxyBackDeleteAudioComment = (params) => {
    return request.post(`/back/business/school/delRecordingComment?${qs.stringify(params, { indices: false })}`)
}
/* 录音评价分页查询 */
export const apiSxyBackGetAudioCommentList = (params) => {
    return request.get("/back/business/school/recordingComment/pageList", params)
}
/* 设置录音评价是否显示 */
export const apiSxyBackSetAudioCommentIsShow = (params) => {
    return request.post(`/back/business/school/setIsShow?${qs.stringify(params, { indices: false })}`)
}
/* 设置录音评价置顶 */
export const apiSxyBackSetAudioCommentIsTop = (params) => {
    return request.post(`/back/business/school/setIsTop?${qs.stringify(params, { indices: false })}`)
}
/* 设置录音学习星级 */
export const apiSxyBackSetAudioLearnLevel = (params) => {
    return request.post(`/back/business/school/setRecordingStar?${qs.stringify(params, { indices: false })}`)
}
/* 商学院-录音学习-前端展示 */
/* 获取资料推荐集合（旧的 通过好的标签推荐 | 新的 通过学科年级类别推荐） */
export const apiSxyFrontGetRecoFileLearnList = (params) => {
    return request.get("/back/recording/learn/getLearnMaterialCommList", params)
}
/* 听过还听 */
export const apiSxyFrontGetAudioAgainList = (params) => {
    return request.get("/front/business/school/againListenList", params)
}
/* 是否收藏录音 */
export const apiSxyFrontIsCollectWebAudio = (params) => {
    return request.post(`/front/business/school/collectRecording?${qs.stringify(params, { indices: false })}`)
}
/* 删除录音评论 */
export const apiSxyFrontDeleteAudioComment = (params) => {
    return request.post(`/front/business/school/delRecordingComment?${qs.stringify(params, { indices: false })}`)
}
/* 删除录音评论点赞 */
export const apiSxyFrontDeleteAudioCommentLike = (params) => {
    return request.post(`/front/business/school/delRecordingCommentThumbUp?${qs.stringify(params, { indices: false })}`)
}
/* 录音评论详情 */
export const apiSxyFrontGetAudioCommentDetail = (params) => {
    return request.get("/front/business/school/getRecordingComment", params)
}
/* 录音学习分页查询 */
export const apiSxyFrontGetAudioLearnList = (params) => {
    return request.get("/front/business/school/recordingLearn/pageList", params)
}
/* 新增录音评论 */
export const apiSxyFrontAddAudioComment = (params) => {
    return request.post("/front/business/school/saveRecordingComment", params)
}
/* 新增录音评论点赞 */
export const apiSxyFrontAddAudioCommentLike = (params) => {
    return request.post(`/front/business/school/saveRecordingCommentThumbUp?${qs.stringify(params, { indices: false })}`)
}
/* 修改录音评论 */
export const apiSxyFrontEditAudioComment = (params) => {
    return request.post("/front/business/school/updateRecordingComment", params)
}

/* 学习资料相关 */
/* 学习资料-后台 */
/* 学习资料分页查询 */
export const apiSxyBackGetFileLearnList = (params) => {
    return request.get("/back/business/school/material/pageList", params)
}
/* 删除学习资料 */
export const apiSxyBackDeleteFileLearn = (params) => {
    return request.post(`/back/business/school/delMaterialById?${qs.stringify(params, { indices: false })}`)
}
/* 新增学习资料 */
export const apiSxyBackAddFileLearn = (params) => {
    return request.post("/back/business/school/saveMaterial", params)
}
/* 设置学习资料发布 */
export const apiSxyBackSetIsPublish = (params) => {
    return request.post(`/back/business/school/setMaterialIsPublish?${qs.stringify(params, { indices: false })}`)
}
/* 设置学习资料置顶 */
export const apiSxyBackSetIsTop = (params) => {
    return request.post(`/back/business/school/setMaterialIsTop?${qs.stringify(params, { indices: false })}`)
}
/* 修改学习资料 */
export const apiSxyBackEditFileLearn = (params) => {
    return request.post("/back/business/school/updateMaterial", params)
}
/* 学习资料详情 */
export const apiSxyBackGetFileLearnDetail = (params) => {
    return request.get("/back/business/school/getMaterialDetail", params)
}
/* 学习资料-前台 */
/* 学习资料分页查询 */
export const apiSxyFrontGetFileLearnList = (params) => {
    return request.get("/front/business/school/material/pageList", params)
}
/* 新增调用学习资料 */
export const apiSxyFrontAddInvokingFileLearn = (params) => {
    return request.post(`/front/business/school/saveInvokingMaterial?${qs.stringify(params, { indices: false })}`)
}
/* 删除调用学习资料 */
export const apiSxyFrontDeleteInvokingFileLearn = (params) => {
    return request.post(`/front/business/school/delInvokingMaterial?${qs.stringify(params, { indices: false })}`)
}
/* 学习资料详情 */
export const apiSxyFrontGetFileLearnDetail = (params) => {
    return request.get("/front/business/school/getMaterialDetail", params)
}
/* 学习资料是否收藏 */
export const apiSxyFrontSetFileLearnIsCollect = (params) => {
    return request.post(`/front/business/school/collectMaterial?${qs.stringify(params, { indices: false })}`)
}

/* 商学院-视频学习 */
/* 商学院-视频学习-后台 */
/* 封面库 */
/* 封面库集合 */
export const apiSxyGetVideoCoverList = () => {
    return request.get("/back/business/school/coverStoreList")
}
/* 删除封面库 */
export const apiSxyDeleteVideoCover = (params) => {
    return request.post(`/back/business/school/delCoverStoreById?${qs.stringify(params, { indices: false })}`)
}
/* 新增封面 */
export const apiSxyAddVideoCover = (params) => {
    return request.post("/back/business/school/saveCoverStore", params)
}
/* 视频总的标签保存 */
export const apiSxySaveVideoTag = (params) => {
    return request.post(`/back/label/saveVideo?${qs.stringify(params, { indices: false })}`)
}
/* 视频总的标签获取 */
export const apiSxyGetVideoTag = () => {
    return request.get("/back/label/getVideoDetail")
}
/* 视频总的标签修改 */
export const apiSxyEditVideoTag = (params) => {
    return request.post(`/back/label/updateVideo?${qs.stringify(params, { indices: false })}`)
}
/* 新增视频学习 */
export const apiSxyBackAddVideoLearn = (params) => {
    return request.post("/back/business/school/saveLearnVideo", params)
}
/* 新增视频学习内 */
export const apiSxyBackAddVideoItem = (params) => {
    return request.post("/back/business/school/saveLearnVideoPackage", params)
}
/* 删除视频学习内外 */
export const apiSxyBackDeleteVideoLearnAll = (params) => {
    return request.post(`/back/business/school/delVideoById?${qs.stringify(params, { indices: false })}`)
}
/* 删除视频学习内 */
export const apiSxyBackDeleteVideoLearnItem = (params) => {
    return request.post(`/back/business/school/delVideoPackageById?${qs.stringify(params, { indices: false })}`)
}
/* 获取视频学习详情 */
export const apiSxyBackGetVideoLearnDetail = (params) => {
    return request.post(`/back/business/school/getLearnVideoDetail?${qs.stringify(params, { indices: false })}`)
}
/* 设置视频学习上架下架 */
export const apiSxyBackSetVideoLearnIsShelves = (params) => {
    return request.post(`/back/business/school/setVideoShelves?${qs.stringify(params, { indices: false })}`)
}
/* 设置视频学习置顶 */
export const apiSxyBackSetVideoLearnIsTop = (params) => {
    return request.post(`/back/business/school/setVideoTop?${qs.stringify(params, { indices: false })}`)
}
/* 修改视频学习外 */
export const apiSxyBackEditVideoLearnAll = (params) => {
    return request.post("/back/business/school/updateLearnVideo", params)
}
/* 修改视频学习内 */
export const apiSxyBackEditVideoLearnItem = (params) => {
    return request.post("/back/business/school/updateLearnVideoPackage", params)
}
/* 视频学习分页查询 */
export const apiSxyBackGetVideoLearnList = (params) => {
    return request.get("/back/business/school/video/pageList", params)
}
/* 视频评价分页查询 */
export const apiSxyBackGetVideoLearnCommentList = (params) => {
    return request.get("/back/business/school/videoComment/pageList", params)
}
/* 视频级数分页查询 */
export const apiSxyBackGetVideoPackageList = (params) => {
    return request.get("/back/business/school/videoPackage/pageList", params)
}
/* 视频评价是否置顶 */
export const apiSxyBackSetVideoLearnCommentIsTop = (params) => {
    return request.post(`/back/business/school/setVideoIsTop?${qs.stringify(params, { indices: false })}`)
}
/* 视频评价删除 */
export const apiSxyBackDeleteVideoLearnComment = (params) => {
    return request.post(`/back/business/school/delVideoComment?${qs.stringify(params, { indices: false })}`)
}
/* 视频评价是否显示 */
export const apiSxyBackSetVideoLearnCommentIsShow = (params) => {
    return request.post(`/back/business/school/setVideoIsShow?${qs.stringify(params, { indices: false })}`)
}
/* 商学院-视频学习-前台 */
/* 获取视频推荐列表 */
export const apiSxyFrontGetVideoRecoList = (params) => {
    return request.post(`/front/business/school/getRecommendToYouList?${qs.stringify(params, { indices: false })}`)
}
/* 视频收藏取消收藏 */
export const apiSxyFrontSetVideoLearnIsCollect = (params) => {
    return request.post(`/front/business/school/collectVideo?${qs.stringify(params, { indices: false })}`)
}
/* 获取视频评价详情 */
export const apiSxyFrontGetVideoCommentDetail = (params) => {
    return request.get("/front/business/school/getVideoComment", params)
}
/* 新增视频评价点赞 */
export const apiSxyFrontAddVideoCommentLike = (params) => {
    return request.post(`/front/business/school/saveVideoCommentThumbUp?${qs.stringify(params, { indices: false })}`)
}
/* 删除视频评论点赞 */
export const apiSxyFrontDeleteVideoCommentLike = (params) => {
    return request.post(`/front/business/school/delVideoCommentThumbUp?${qs.stringify(params, { indices: false })}`)
}
/* 删除视频评论 */
export const apiSxyFrontDeleteVideoComment = (params) => {
    return request.post(`/front/business/school/delVideoComment?${qs.stringify(params, { indices: false })}`)
}
/* 新增视频评论 */
export const apiSxyFrontAddVideoComment = (params) => {
    return request.post("/front/business/school/saveVideoComment", params)
}
/* 修改视频评论 */
export const apiSxyFrontEditVideoComment = (params) => {
    return request.post("/front/business/school/updateVideoComment", params)
}
/* 增加视频点击量 */
export const apiSxyFrontAddVideoLearnPlayNum = (params) => {
    return request.post(`/front/business/school/addPlayNum?${qs.stringify(params, { indices: false })}`)
}
/* 视频学习详情 */
export const apiSxyFrontGetVideoLearnDetail = (params) => {
    return request.post(`/front/business/school/getLearnVideoDetail?${qs.stringify(params, { indices: false })}`)
}
/* 上架视频讲师集合 */
export const apiSxyFrontGetTeacherList = (params) => {
    return request.get("/front/business/school/getTeacherList", params)
}
/* 视频学习分页查询 */
export const apiSxyFrontGetVideoLearnList = (params) => {
    return request.get("/front/business/school/video/pageList", params)
}


/* 商学院-前台-首页 */
export const apiSxyGetHomeList = (params) => {
    return request.get("/front/business/school/firstPage/List", params)
}

/* 商学院-升学政策 */
/* 商学院-升学政策-后台 */
/* 删除升学政策 */
export const apiSxyBackDeleteNews = (params) => {
    return request.post(`/back/business/school/delInformationById?${qs.stringify(params, { indices: false })}`)
}
/* 升学政策详情 */
export const apiSxyBackGetNewsDetail = (params) => {
    return request.post(`/back/business/school/getInformation?${qs.stringify(params, { indices: false })}`)
}
/* 升学政策分页查询 */
export const apiSxyBackGetNewsList = (params) => {
    return request.get("/back/business/school/information/pageList", params)
}
/* 升学政策置顶 */
export const apiSxyBackSetNewsIsTop = (params) => {
    return request.post(`/back/business/school/setInformationTop?${qs.stringify(params, { indices: false })}`)
}
/* 修改升学政策 */
export const apiSxyBackEditNews = (params) => {
    return request.post("/back/business/school/updateInformation", params)
}
/* 新增升学政策 */
export const apiSxyBackAddNews = (params) => {
    return request.post("/back/business/school/saveInformation", params)
}
/* 商学院-升学政策-前台 */
/* 收藏取消收藏升学政策 */
export const apiSxyFrontIsCollectNews = (params) => {
    return request.post(`/front/business/school/collectInformation?${qs.stringify(params, { indices: false })}`)
}
/* 获取升学政策列表 */
export const apiSxyFrontGetNewsList = (params) => {
    return request.get("/front/business/school/information/pageList", params)
}
/* 获取升学政策详情 */
export const apiSxyFrontGetNewsDetail = (params) => {
    return request.post(`/front/business/school/getInformation?${qs.stringify(params, { indices: false })}`)
}
/* 商学院-讨论中心 */
/* 商学院-讨论中心-后台 */
/* 删除讨论中心 */
export const apiSxyBackDeleteDiscussion = (params) => {
    return request.post(`/back/business/school/delDiscussCenterById?${qs.stringify(params, { indices: false })}`)
}
/* 讨论中心分页查询 */
export const apiSxyBackGetDiscussionList = (params) => {
    return request.get("/back/business/school/discussCenter/pageList", params)
}
/* 讨论中心详情 */
export const apiSxyBackGetDiscusstionDetail = (params) => {
    return request.post(`/back/business/school/getDiscussCenter?${qs.stringify(params, { indices: false })}`)
}
/* 新增讨论中心 */
export const apiSxyBackAddDiscusstion = (params) => {
    return request.post("/back/business/school/saveDiscussCenter", params)
}
/* 讨论中心置顶 */
export const apiSxyBackSetDiscusstionIsTop = (params) => {
    return request.post(`/back/business/school/setDiscussCenterTop?${qs.stringify(params, { indices: false })}`)
}
/* 修改讨论中心 */
export const apiSxyBackEditDiscusstion = (params) => {
    return request.post("/back/business/school/updateDiscussCenter", params)
}
/* 删除讨论中心评价 */
export const apiSxyBackDeleteDiscussionComment = (params) => {
    return request.post(`/back/business/school/delDiscussCenter?${qs.stringify(params, { indices: false })}`)
}
/* 讨论中心评价置顶 */
export const apiSxyBackSetDiscussionCommentIsTop = (params) => {
    return request.post(`/back/business/school/setDiscussCenterIsTop?${qs.stringify(params, { indices: false })}`)
}
/* 讨论中心录音转文字 */
export const apiSxyBackDiscussionAudioTransTxt = (params) => {
    return request.post(`/back/business/school/discussCenterConvertText?${qs.stringify(params, { indices: false })}`)
}
/* 获取讨论中心录音转文字状态 */
export const apiSxyBackGetDiscussionAudioTransTxtStatus = (params) => {
    return request.post(`/back/business/school/getConvertTextStatus?${qs.stringify(params, { indices: false })}`)
}
/* 修改讨论中心录音转文字内容 */
export const apiSxyBackEditDiscussionAudioTransTxt = (params) => {
    return request.post(`/back/business/school/updateDiscussCenterContent?${qs.stringify(params, { indices: false })}`)
}
/* 商学院-讨论中心-前台 */
/* 收藏/取消收藏讨论中心 */
export const apiSxyFrontIsCollectDiscussion = (params) => {
    return request.post(`/front/business/school/collectDiscussCenter?${qs.stringify(params, { indices: false })}`)
}
/* 删除讨论中心评价 */
export const apiSxyFrontDeleteDiscussionComment = (params) => {
    return request.post(`/front/business/school/delDiscussCenter?${qs.stringify(params, { indices: false })}`)
}
/* 取消讨论中心评价点赞 */
export const apiSxyFrontDeleteDiscussionCommentLike = (params) => {
    return request.post(`/front/business/school/delDiscussCenterCommentThumbUp?${qs.stringify(params, { indices: false })}`)
}
/* 删除讨论中心 */
export const apiSxyFrontDeleteDiscussion = (params) => {
    return request.post(`/front/business/school/delDiscussCenterById?${qs.stringify(params, { indices: false })}`)
}
/* 讨论中心分页查询 */
export const apiSxyFrontGetDiscussionList = (params) => {
    return request.get("/front/business/school/discussCenter/pageList", params)
}
/* 讨论中心详情 */
export const apiSxyFrontGetDiscussionDetail = (params) => {
    return request.post(`/front/business/school/getDiscussCenter?${qs.stringify(params, { indices: false })}`)
}
/* 新增讨论中心评价 */
export const apiSxyFrontAddDiscussionComment = (params) => {
    return request.post("/front/business/school/saveDiscussCenterComment", params)
}
/* 新增讨论中心 */
export const apiSxyFrontAddDiscussion = (params) => {
    return request.post("/front/business/school/saveDiscussCenter", params)
}
/* 修改讨论中心 */
export const apiSxyFrontEditDiscussion = (params) => {
    return request.post("/front/business/school/updateDiscussCenter", params)
}
/* 新增讨论中心评论点赞 */
export const apiSxyFrontAddDiscussionCommentLike = (params) => {
    return request.post(`/front/business/school/saveDiscussCenterCommentThumbUp?${qs.stringify(params, { indices: false })}`)
}
/* 修改讨论中心评论 */
export const apiSxyFrontEditDiscussionComment = (params) => {
    return request.post("/front/business/school/updateDiscussCenterComment", params)
}
/* 获取讨论中心评论列表 */
export const apiSxyFrontGetDiscussionCommentDetail = (params) => {
    return request.post(`/front/business/school/getDiscussCenterCommentList?${qs.stringify(params, { indices: false })}`)
}

/* 我的收藏 */
/* 收藏首页 */
export const apiSxyGetCollectHomeList = (params) => {
    return request.get("/front/business/school/myCollect/List", params)
}




/* 空号检测 */
/* 合并两个库的数据 */
export const apiMergeAccountData = () => {
    return request.post("/merge/accountTwo", {}, {
        timeout: 0,   // 导入文件超时时间
    })
}
/* 检测重复数据 */
export const apiCheckRepeatAcountData = (file) => {
    let formData = new FormData()
    formData.append('files', file)
    return request.post("/merge/checkRepeatData", formData, {
        headers: {
            'Content-type': 'multipart/form-data'
        },
        timeout: 0,   // 导入文件超时时间
    })
}
/* 获取数据检测详情 */
export const apiGetCheckRepeatSetDetail = () => {
    return request.get("/merge/getDetail")
}
/* 新增/修改数据检测 */
export const apiSetCheckRepeat = (params) => {
    return request.post("/merge/saveOrUpdate", params)
}
/* 获取最后一次合并时间 */
export const apiGetLastMergeInfo = () => {
    return request.post("/merge/getLastMergeTime")
}